import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchTrial,
  clearTrial,
  fetchRecurring,
  clearRecurring,
  fetchLicenses,
  fetchCoupons,
  fetchBillingInfo,
  clearBillingInfo,
} from "store/resources";
import PubSub from "pubsub-js";

import AppHeader from "./Header";
import UserPanel from "./UserPanel/index.js";
import MembershipPanel from "./MembershipPanel";
import PurchasePanel from "./PurchasePanel";
import MusioButton from "components/MusioButton";

import "./style.css";

const Dashboard = (props) => {
  const [showPI, setShowPI] = useState(false);

  const refreshMembership = () => {
    clearRecurring();
    clearBillingInfo();
    clearTrial();
    setTimeout(() => {
      Promise.all([
        fetchTrial(),
        fetchBillingInfo(),
        fetchRecurring(),
        fetchLicenses(),
        fetchCoupons(),
      ]);
    }, 1000);
  };

  const [subRefreshMembership] = useState(
    PubSub.subscribe("RefreshMembership", (msg, data) => {
      refreshMembership();
    })
  );

  const setShowPaymentInfo = (flag) => {
    setShowPI(flag);
  };

  useEffect(() => {
    // mount
    refreshMembership();
  }, []);

  useEffect(
    () => () => {
      // unmount
      PubSub.unsubscribe(subRefreshMembership);
    },
    []
  );

  return (
    <div className="dashboard-container">
      <AppHeader />
      <div className="dashboard-flex">
        <UserPanel />
        <MembershipPanel
          showPaymentInfo={showPI}
          setShowPaymentInfo={setShowPaymentInfo}
        />
        <PurchasePanel
          showPaymentInfo={showPI}
          setShowPaymentInfo={setShowPaymentInfo}
        />
      </div>
    </div>
  );
};

export default Dashboard;
