import React from 'react';
import './style.css';

import LoadingRing from 'components/LoadingRing';

const MusioButton = (props) => {
  let disabled = props.disabled || false;
  let button_type = props.buttonType || "round";  //round or squared
  let background = props.background || "red";
  let loading = props.loading || false;
  let size = props.size || "large";
  let type = props.type || "";

  return (
    <button className={`musio-button button ${button_type} ${background} ${size}`} type={type} onClick={props.onClick} disabled={disabled}>
      <div className="button-container">
        <div className={loading ? "text-hidden" : ""}>
          <span>{props.text}</span>
        </div>
        <div className={"button-overlay " + (loading ? "" : " overlay-hidden")}>
          <LoadingRing/>
        </div>
      </div>      
    </button>
  );
};
  
export default MusioButton;